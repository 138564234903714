<template>
  <div id="app">
    <app-header/>

    <div class="columns is-multiline is-fullwidth pl-6 pr-6">
      <div class="column is-full is-flex p-0 has-text-centered is-flex" style="height: 20em;">
        <h1 class="full-with m-auto">Meet the Team</h1>
      </div>

      <modal-about ref="about" @close="doShowMember"></modal-about>

      <div class="column columns m-auto is-full columns mb-4" v-for="(chunk, indexA) in chunkedMembers" :key="indexA">
        <div class="column is-half pl-4 pr-4" v-for="(member, indexB) in chunk" :key="indexB">
          <user :name="member.name" :description="member.description" :background="member.background" :pyramid="member.pyramid" :deadSeaDivingSociety="member.deadSeaDivingSociety" @doShowUser="doShowMember"></user>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script type="text/javascript">
import appHeader from '@/app/components/navbar.vue'
import footer from '@/app/components/footer.vue'
import user from '@/app/components/user.vue'
import modalAbout from '@/app/components/modalAbout.vue'

export default {
  components: {
    appHeader: appHeader,
    Footer: footer,
    user: user,
    modalAbout: modalAbout
  },
  data: () => ({
    members: [
      {
        name: 'Justin Schneider',
        description: 'I am one of two co-founders of Pyramid Dive Club and the Dead Sea Diving Society. As the dive leader of both teams, I am a well-rounded dive instructor and have extensive experience in international adventure travel. I have been diving professionally since 2012 in Croatia, after leaving a career in counter terrorism and intelligence. I relocated back to the US in 2013 and became a full-time dive instructor in a local shop. \n' +
          'I\'ve been fortunate enough to be a part of this incredible club that has given me the chance to discover some truly one-of-a-kind underwater worlds. Through the P.D.C., I was able to not just see history but make it with being the first diver to enter a flooded tomb. This tomb was buried beneath a Sudanese pyramid in the blistering Sahara Desert. Plunging myself into the fascinating history and intrigue of this inundated marvel, I was addicted. Likewise, with the Dead Sea Diving Society, I journeyed to the banks of the Dead Sea in Jordan - a truly unique place with its own natural beauty and logistical nightmares to face, due to its incredibly high salt content diving is extremely difficult.\n' +
          'My desire for adventure led me to learn a vast amount of diving specialties, including deep, technical, full cave diving, and even creating my own PADI Dead Sea Diver course. I have completed over 75 diving certifications spread across three different dive agencies. As a PDC team member, I have contributed my expertise in difficult logistics, high-risk travel, and proper gear selection for each exclusive project. \n' +
          'Rather it be diving under a cursed flooded tomb, hiking through the vast ageless Petra, pub crawling through Moscow’s seedy neighborhoods, or just plain international mischief I am an asset to have on any team. From Prague to Bogota, I know the ins and outs of international adventure travel. \n',
        background: '/img/profile/justin-schneider.jpg',
        pyramid: true,
        deadSeaDivingSociety: true
      },
      {
        name: 'Professor Pearce Paul Creasman',
        description: 'Be Besides being the co-founder of the Pyramid Dive Club, I am an archaeologist in the fields of Egyptology, maritime archaeology, and dendrochronology. I currently serve as the Executive Director of the American Center of Research (acorjordan.org) and previously was a professor and curator at the University of Arizona. \n' +
          'My primary research interests are focused on the heritage, archaeology, and environment of the Middle East and North Africa, where I have been conducting fieldwork and excavations for more than two decades. I have authored or co-authored more than 100 articles and edited books, held a number of professional offices, and received several academic and educational honors and awards, including recognition from the White House\'s Office of Science and Technology Policy and National Geographic. My doctorate is from the Nautical Archaeology Program at Texas A&M University.\n',
        background: '/img/profile/pierce.jpg',
        pyramid: true,
        deadSeaDivingSociety: true
      },
      {
        name: 'David Dunn',
        description: 'As an experienced diver with an insatiable curiosity for the oceanic depths, I have been blessed with an innate sense of adventure and a lifelong journey filled with diverse opportunities and experiences across the world. My diverse background has gifted me with a varied set of skills; I am the proverbial Swiss Army knife of any expedition team. Whether it\'s an expedition into ancient tombs, flying the team through the clouds, or exploring the uncharted waters of adventure, I am always excited to dive headfirst into any experience! \n' +
          'I obtained my dive instructor certification in Honduras, and since then, I have been an integral member of the Salt & Sea Scuba team for three years. My adventures in diving have been well-documented in numerous P.D.C. TV specials and print articles, making me something of a veteran in the field. My passion for travel perfectly complements my adventurous spirit and conscientiously helps me fit in well within the Pyramid Dive Club.\t\n',
        background: '/img/profile/david.jpg',
        pyramid: true,
        deadSeaDivingSociety: false
      },
      {
        name: 'Fred Fidler',
        description: 'As my love for diving has grown, I pursued further training and certifications, including technical and cave diving coursers. Drawing upon my background as a former US Army medic and nurse, I embraced the challenges of technical and overhead environment diving. Combining my extensive medical experience with diving, I\'ve had the privilege of serving as the team medic for both esteemed organizations the Pyramid Dive Club and Dead Sea Diving Society.\n' +
          'Being a member of this prestigious club has afforded me some remarkable opportunities to explore unique underwater environments. With the Pyramid Dive Club, I contributed to the exploration of the flooded tomb beneath the pyramid of Nastasen in Sudan, immersing myself in the ancient history and mystery of this submerged wonder.\n' +
          'Similarly, as part of the Dead Sea Diving Society, I ventured to the Dead Sea in Jordan—a place of extraordinary buoyancy due to its high salinity. Descending into its mineral-rich waters required an astonishing 85 pounds of lead, an experience that left me both awestruck and humbled.\n' +
          'I take great pride in my affiliation with the Pyramid Dive Club and the Dead Sea Diving Society, as they have provided me with unparalleled opportunities to dive and explore remarkable parts of the world. These experiences have not only enriched my life but have also deepened my appreciation for the beauty and wonder of the underwater realm.\n',
        background: '/img/profile/fred.jpg',
        pyramid: true,
        deadSeaDivingSociety: true
      },
      {
        name: 'Randy Parker',
        description: 'I am the newest diver added to the Pyramid Dive Club, and recently became a member of the Dead Sea Diving Society after completing my inaugural expedition with the dive team, which recently was part of an underwater archaeological excavation in Jordan, along the shore of the famous Dead Sea.  \n' +
          'In 2008, I started my journey in Scuba diving with an Open Water Diver certification.  By 2010, I had completed the Dive Master Program for my first pro -level certification in Scuba.  In 2023, I took an additional professional step by becoming a full dive Instructor and joined the team at Salt and Sea Scuba, a premier PADI 5-Star Training Center located in Phoenix, AZ. \n' +
          'In the last year, I\'ve been working hard to enhance my diving skills by earning a range of challenging certifications including Intro to TEC, Full Face Mask, Drysuit, and even Full Cave diving. Looking towards the future, my goal is to expand my expertise by undertaking more training programs in both recreational and technical diving. My aim is to become a better diver, instructor, and member of the dive team.\n',
        background: '/img/profile/randy-parker.jpg',
        pyramid: false,
        deadSeaDivingSociety: true
      },
      {
        name: 'Josh Gates',
        description: 'An avid SCUBA diver, I have assisted in sub-sea archaeological excavations off the coast of Israel, and as a photographer I have traveled to remote villages in Bangladesh and Nepal. Scaled Mt. Kilimanjaro and climbed on Aconcagua, the tallest mountain in the Americas. I am passionate about photographing the unique places I encounter and the diverse people I meet along the way.\n' +
          '\tSo of course I would jump at the opportunity to be part of the Pyramid Dive Club! I became a full patch member in 2023 while filming two episodes for "Expedition Unknown". \n',
        background: '/img/profile/josh-gates.jpg',
        pyramid: true,
        deadSeaDivingSociety: false
      },
      {
        name: 'Ryan Renuart',
        description: 'While working for US Department of State in Sudan doing xxxxxxxx and xxxxxx  I came in contact with Justin while helping xxxxxxxxxxxx xxxxx and learned about a crazy adventure in the Northern part. Myself being an avid diver and hearing about the Pyramid Dive Club I had to do what to took to earn a spot on the members board. So I started xxxxx and xxxxxxxxxxx , three days later I was in a tomb receiving the much coveted PDC patch. Even with my new posting in xxxxxx xx I still try support the PDC anyway I can. ',
        background: '/img/profile/ryan-renuart.jpg',
        pyramid: true,
        deadSeaDivingSociety: false
      }
    ]
  }),
  methods: {
    doShowMember (name, description) {
      this.$refs.about.toggleModal(name, description)
    }
  },
  computed: {
    chunkedMembers () {
      const chunkArray = []

      const chunkSize = 2
      for (let i = 0; i < this.members.length; i += chunkSize) {
        chunkArray.push(this.members.slice(i, i + chunkSize))
      }

      return chunkArray
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
