<template>
  <div id="app">
    <app-header></app-header>

    <div class="columns is-fullwidth has-background-white mb-0 is-flex-mobile ml-0-mobile mr-0-mobile min-width-100-vw" style="height: calc(100vh - 54px); margin-bottom: 12px !important;">
        <b-carousel class="is-fullheight mobile-only" style="width: 100%; height: 100%" :arrow="false" :pause-info="false" :indicator="false" :interval="8000">
          <b-carousel-item style="height: 100%" class="is-fullheight" v-for="(image, i) in images" :key="i">
            <div :style="{'background-image': 'url(' + image.link + ')'}" style="height: 100%; background-position: center; background-size: cover">
            </div>
          </b-carousel-item>
        </b-carousel>

      <div class="flex-mobile-only is-fullheight is-grid has-text-centered is-align-self-center is-absolute" style="z-index: 1; height: calc(100vh - 52px); width: 100%; right: 0; box-shadow: inset 0 0 200px 200px rgba(0,0,0,0.5)">
        <h1 class="has-text-white has-text-weight-bold is-fullwidth mt-auto mb-auto" style="width: 100%">Pyramid Dive Club</h1>
      </div>

      <div class="column p-0 is-two-thirds desktop-only">
        <b-carousel class="is-fullheight" style="width: 100%; height: 100%" :arrow="false" :pause-info="false" :indicator="false" :interval="8000">
          <b-carousel-item style="height: 100%" class="is-fullheight" v-for="(image, i) in images" :key="i">
            <div :style="{'background-image': 'url(' + image.link + ')'}" style="height: 100%; background-position: center; background-size: cover"/>
          </b-carousel-item>
        </b-carousel>
      </div>

      <div class="column is-flex desktop-only has-text-centered hero-title has-background-black has-text-white">
        <div>
          <img src="/img/pyramid/logo_white_fill.png" alt="Pyramid Dive Club" class="hero-logo">
          <h1 class="">Pyramid Dive Club</h1>
          <img src="/img/pyramid/Dead-Sea-Diving-Society-Logo.png" alt="Pyramid Dive Club" class="hero-logo">
        </div>
      </div>
    </div>

    <!--- NUMBER ONE PARALLAX -->
    <div class="parallax columns image-1 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw">
      <div class="column mt-auto mb-auto is-two-thirds is-full-mobile pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered">
        <div class="columns is-multiline">
          <div class="column is-full mt-auto">
            <h5 class="has-text-weight-bold is-size-4-mobile">Diving into History</h5>
          </div>
          <div class="column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile">
            <p class="px-6">
              The Pyramid Dive Club, renowned for its passion for underwater exploration and historical discovery, recently embarked on an extraordinary adventure beneath the sands of Sudan, exploring the submerged tombs of the Nuri pyramids. This groundbreaking dive not only showcased our club's expertise in navigating challenging underwater environments but also brought us face-to-face with artifacts from the ancient Kushite civilization.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--- NUMBER ONE HERO CONTENT -->
    <div class="columns has-background-black has-text-white p-6 is-multiline ml-0-mobile mr-0-mobile min-width-100-vw">
      <div class="column is-full has-text-centered">
        <h1 class="has-text-weight-bold is-size-3-mobile">Not just Divers, Pioneers of the Depths</h1>
      </div>
    </div>

    <!--- NUMBER TWO PARALLAX -->
    <div class="parallax columns image-2 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw">
      <div class="column mt-auto mb-auto is-two-thirds is-full-mobile pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered ml-auto">
        <div class="columns is-multiline">
          <div class="column is-full mt-auto">
            <h5 class="has-text-weight-bold is-size-4-mobile">Perspective</h5>
          </div>
          <div class="column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile">
            <p class="px-6">
              Embarking on the kind of dives that delve into the heart of history beneath the waves requires not just physical training but a profound shift in perspective. Divers must hone their skills in navigating the delicate balance between exploration and preservation, understanding that they are not just visitors but guardians of underwater sanctuaries. It's a journey that transforms a diver from a mere observer to an explorer, where each dive is a step into the unknown, demanding respect for the past and a commitment to the future of our shared heritage.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--- NUMBER THREE PARALLAX -->
    <div class="parallax columns image-3 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw">
      <div class="column mt-auto mb-auto is-two-thirds is-full-mobile p-10 pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered">
        <div class="columns is-multiline">
          <div class="column is-full mt-auto">
            <h5 class="is-size-4-mobile">Our goals</h5>
          </div>
          <div class="column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile">
            <p class="px-6">
              The Pyramid Dive Club is on a mission to redefine the limits of underwater exploration, targeting the world's most challenging and uncharted dives. Our aim is to be trailblazers, undertaking complex and serious dives that push the boundaries of the possible. Each dive is an opportunity to discover unseen history and set new benchmarks in the diving community.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--- NUMBER TWO HERO CONTENT -->
    <div class="columns p-6 is-multiline min-width-100-vw ml-0-mobile mr-0-mobile">
      <div class="column columns m-auto is-multiline is-full has-text-centered">
        <div style="display: flex; flex-direction: row; justify-content: space-between" class="m-auto px-4 columns is-multiline">
          <div class="column is-full-mobile is-3-desktop m-auto">
            <a href="https://www.nationalgeographic.com/podcasts/overheard/article/episode-4-scuba-diving-underwater-pyramid" target="_blank">
              <img style="height: 50px !important;" src="/img/other/NG-Logo.jpg" alt="National Geographic">
            </a>
          </div>
          <div class="column is-full-mobile is-3-desktop m-auto">
            <a href="https://www.bbc.com/news/world-africa-48867830" target="_blank">
              <img style="height: 50px !important;" src="/img/other/BBC-Logo.jpeg" alt="National Geographic">
            </a>
          </div>
          <div class="column is-full-mobile is-3-desktop m-auto">
            <a href="https://www.iflscience.com/archaeologists-underwater-exploration-of-sudan-pyramids-is-like-something-out-of-tomb-raider-52994" target="_blank">
              <img style="height: 50px !important;" src="/img/other/IflScience-Logo.png" alt="IFL-Science">
            </a>
          </div>
          <div class="column is-full-mobile is-3-desktop m-auto">
            <a href="https://www.youtube.com/watch?v=egsEEjQbDrE" target="_blank">
              <img style="height: 50px !important;" src="/img/other/YouTube-Logo.png" alt="YouTube">
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--- NUMBER FOUR PARALLAX -->
    <div class="parallax columns image-4 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw">
      <div class="column mt-auto mb-auto is-fullheight is-two-thirds is-full-mobile pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered ml-auto">
        <div class="columns is-multiline">
          <div class="column is-full mt-auto">
            <h5 class="has-text-weight-bold is-size-4-mobile">The Elite Depths</h5>
          </div>
          <div class="column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile">
            <p class="px-6 pb-4">In times long past, the civilizations along the Nile crafted over 350 majestic pyramids, of which around 100 still grace the landscape today. A select few of these ancient marvels now guard their secrets beneath the waters, with only one pyramid and its submerged chambers having been explored by divers. This exclusive dive is a privilege reserved for a distinguished few within the Club.</p>
            <p class="px-6">In comparison, over 20,000 intrepid souls have reached the South Pole, more than 5,000 climbers have conquered Mount Everest, upwards of 500 individuals have ventured into space, and just twelve have left their footprints on the Moon.</p>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script type="text/javascript">
import footer from '@/app/components/footer.vue'
import appHeader from '@/app/components/navbar.vue'

export default {
  methods: {
  },
  components: {
    appHeader: appHeader,
    Footer: footer
  },
  data: () => ({
    images: [
      { link: '/img/pyramid/c222dfad-82ae-452e-a0ef-e9894774b2fe.jpg', alt: 'Helmet Logo' },
      { link: '/img/pyramid/IMG_1393.jpeg', alt: 'Man Sitting With Rope' },
      { link: '/img/pyramid/D572895F-6933-46CE-8B50-0E4D0B554691.jpg', alt: 'Standing Next to Entrance' },
      { link: '/img/pyramid/IMG_3135.jpeg', alt: 'Setting Up Gear' },
      { link: '/img/pyramid/43830252-1430-4600-ba5e-5437def5386f.jpg', alt: 'Getting Ready At the Site' }
    ]
  })
}
</script>

<style scoped>
.hero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.hero-logo {
  height: 200px !important;
}
</style>
