<template>
  <section>
    <footer class="footer p-0 has-background-black">
      <div class="container pb-4 has-text-white">
        <div class="content ml-auto mr-auto">
          <div class="pt-2" style="display: flex; flex-direction: row; align-content: center; justify-content: center;">
            <div>
              <span>
                Site hosted and created by
              </span>
              <a href="https://unixity.co?tracking=Pyramid-Dive-Club" target="_blank" style="cursor: pointer; display: inline-block; vertical-align: middle;" class="mt-3 text-decoration-none">
                <unixity-logo-text :width="100" :height="70"></unixity-logo-text>
              </a>
            </div>
          </div>
          <div class="pt-2 has-text-centered">
            Copyright © {{ new Date().getFullYear() }} - <span class="has-text-white has-text-weight-bold">{{ this.companyName }}</span> - All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import UnixityLogoText from '@/app/components/unixityLogoText.vue'

export default {
  components: {
    UnixityLogoText: UnixityLogoText
  },
  data: () => ({
    companyName: 'Pyramid Dive Club'
  })
}
</script>
