<template>
  <section>
    <b-navbar shadow fixed-top :class="visible ? '' : 'hidden'">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="/img/pyramid/logo.png" alt="Pyramid Dive Club">
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item class="has-text-centered" tag="router-link" :to="{ path: '/' }">
          Home
        </b-navbar-item>
        <b-navbar-item class="has-text-centered" tag="router-link" :to="{ path: '/members' }">
          Members
        </b-navbar-item>
      </template>

      <template #end class="is-fullheight">
        <div class="flex-mobile-only m-0 is-fullheight full-width mb-2 is-bottom is-absolute" style="bottom: 0">
          <b-button class="full-width ml-2 mr-2"><a href="mailto:Membership@PyramidDiveClub.org">Email Us</a></b-button>
        </div>

        <div class="desktop-only m-0 is-fullheight mb-2 is-bottom is-absolute" style="bottom: 0">
          <b-button class="full-with ml-2 mr-2"><a href="mailto:Membership@PyramidDiveClub.org">Email Us</a></b-button>
        </div>
      </template>
    </b-navbar>
  </section>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
    timeout: null,
    visible: true,
    status: {
      user: {
        forceLogout: false,
        isAdmin: true
      },
      DownloadPaused: 'sync-alt',
      DownloadRate: '',
      RemainingSizeMB: 0,
      FreeDiskSpaceMB: 0
    }
  })
}
</script>

<style scoped>
.hidden { display: none }
</style>
