<template>
  <Transition name="modal">
    <div v-if="showModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container mt-0 mb-0 m-6" style="height: 80vh !important; overflow-y: scroll">
          <div class="modal-header">
            <slot name="header" class="is-primary"><h1>{{ name }}</h1></slot>
          </div>

          <div class="modal-body">
            <slot name="body">{{ description }}</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="is-flex">
                <b-button @click="$emit('close')" class="ml-auto is-danger">Close</b-button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  // props: {
  // showModal: Boolean,
  // name: String,
  // description: String
  // },
  data: () => ({
    showModal: false,
    name: '',
    description: ''
  }),
  methods: {
    toggleModal (name, description) {
      this.showModal = !this.showModal

      this.name = name
      this.description = description
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
