<template>
  <div class="app pt-0" id="app">
    <vue-headful title=""/>
    <navbar/>

    <transition name="fade" mode="out-in">
      <router-view class="m-0" style="padding-top: 14px !important;"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
    navbar: require('@/app/components/navbar').default
  }
}
</script>
