const Vue = require('vue').default
const VueRouter = require('vue-router').default

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: require('@/app/pages/home.vue').default,
    meta: {
      requiresAuth: false,
      showNav: false
    }
  },
  {
    path: '/members',
    component: require('@/app/pages/members.vue').default,
    meta: {
      requiresAuth: false,
      showNav: false
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
