var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('app-header'),_c('div',{staticClass:"columns is-fullwidth has-background-white mb-0 is-flex-mobile ml-0-mobile mr-0-mobile min-width-100-vw",staticStyle:{"height":"calc(100vh - 54px)","margin-bottom":"12px !important"}},[_c('b-carousel',{staticClass:"is-fullheight mobile-only",staticStyle:{"width":"100%","height":"100%"},attrs:{"arrow":false,"pause-info":false,"indicator":false,"interval":8000}},_vm._l((_vm.images),function(image,i){return _c('b-carousel-item',{key:i,staticClass:"is-fullheight",staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"height":"100%","background-position":"center","background-size":"cover"},style:({'background-image': 'url(' + image.link + ')'})})])}),1),_vm._m(0),_c('div',{staticClass:"column p-0 is-two-thirds desktop-only"},[_c('b-carousel',{staticClass:"is-fullheight",staticStyle:{"width":"100%","height":"100%"},attrs:{"arrow":false,"pause-info":false,"indicator":false,"interval":8000}},_vm._l((_vm.images),function(image,i){return _c('b-carousel-item',{key:i,staticClass:"is-fullheight",staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"height":"100%","background-position":"center","background-size":"cover"},style:({'background-image': 'url(' + image.link + ')'})})])}),1)],1),_vm._m(1)],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-mobile-only is-fullheight is-grid has-text-centered is-align-self-center is-absolute",staticStyle:{"z-index":"1","height":"calc(100vh - 52px)","width":"100%","right":"0","box-shadow":"inset 0 0 200px 200px rgba(0,0,0,0.5)"}},[_c('h1',{staticClass:"has-text-white has-text-weight-bold is-fullwidth mt-auto mb-auto",staticStyle:{"width":"100%"}},[_vm._v("Pyramid Dive Club")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-flex desktop-only has-text-centered hero-title has-background-black has-text-white"},[_c('div',[_c('img',{staticClass:"hero-logo",attrs:{"src":"/img/pyramid/logo_white_fill.png","alt":"Pyramid Dive Club"}}),_c('h1',{},[_vm._v("Pyramid Dive Club")]),_c('img',{staticClass:"hero-logo",attrs:{"src":"/img/pyramid/Dead-Sea-Diving-Society-Logo.png","alt":"Pyramid Dive Club"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax columns image-1 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw"},[_c('div',{staticClass:"column mt-auto mb-auto is-two-thirds is-full-mobile pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full mt-auto"},[_c('h5',{staticClass:"has-text-weight-bold is-size-4-mobile"},[_vm._v("Diving into History")])]),_c('div',{staticClass:"column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile"},[_c('p',{staticClass:"px-6"},[_vm._v(" The Pyramid Dive Club, renowned for its passion for underwater exploration and historical discovery, recently embarked on an extraordinary adventure beneath the sands of Sudan, exploring the submerged tombs of the Nuri pyramids. This groundbreaking dive not only showcased our club's expertise in navigating challenging underwater environments but also brought us face-to-face with artifacts from the ancient Kushite civilization. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns has-background-black has-text-white p-6 is-multiline ml-0-mobile mr-0-mobile min-width-100-vw"},[_c('div',{staticClass:"column is-full has-text-centered"},[_c('h1',{staticClass:"has-text-weight-bold is-size-3-mobile"},[_vm._v("Not just Divers, Pioneers of the Depths")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax columns image-2 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw"},[_c('div',{staticClass:"column mt-auto mb-auto is-two-thirds is-full-mobile pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered ml-auto"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full mt-auto"},[_c('h5',{staticClass:"has-text-weight-bold is-size-4-mobile"},[_vm._v("Perspective")])]),_c('div',{staticClass:"column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile"},[_c('p',{staticClass:"px-6"},[_vm._v(" Embarking on the kind of dives that delve into the heart of history beneath the waves requires not just physical training but a profound shift in perspective. Divers must hone their skills in navigating the delicate balance between exploration and preservation, understanding that they are not just visitors but guardians of underwater sanctuaries. It's a journey that transforms a diver from a mere observer to an explorer, where each dive is a step into the unknown, demanding respect for the past and a commitment to the future of our shared heritage. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax columns image-3 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw"},[_c('div',{staticClass:"column mt-auto mb-auto is-two-thirds is-full-mobile p-10 pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full mt-auto"},[_c('h5',{staticClass:"is-size-4-mobile"},[_vm._v("Our goals")])]),_c('div',{staticClass:"column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile"},[_c('p',{staticClass:"px-6"},[_vm._v(" The Pyramid Dive Club is on a mission to redefine the limits of underwater exploration, targeting the world's most challenging and uncharted dives. Our aim is to be trailblazers, undertaking complex and serious dives that push the boundaries of the possible. Each dive is an opportunity to discover unseen history and set new benchmarks in the diving community. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns p-6 is-multiline min-width-100-vw ml-0-mobile mr-0-mobile"},[_c('div',{staticClass:"column columns m-auto is-multiline is-full has-text-centered"},[_c('div',{staticClass:"m-auto px-4 columns is-multiline",staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('div',{staticClass:"column is-full-mobile is-3-desktop m-auto"},[_c('a',{attrs:{"href":"https://www.nationalgeographic.com/podcasts/overheard/article/episode-4-scuba-diving-underwater-pyramid","target":"_blank"}},[_c('img',{staticStyle:{"height":"50px !important"},attrs:{"src":"/img/other/NG-Logo.jpg","alt":"National Geographic"}})])]),_c('div',{staticClass:"column is-full-mobile is-3-desktop m-auto"},[_c('a',{attrs:{"href":"https://www.bbc.com/news/world-africa-48867830","target":"_blank"}},[_c('img',{staticStyle:{"height":"50px !important"},attrs:{"src":"/img/other/BBC-Logo.jpeg","alt":"National Geographic"}})])]),_c('div',{staticClass:"column is-full-mobile is-3-desktop m-auto"},[_c('a',{attrs:{"href":"https://www.iflscience.com/archaeologists-underwater-exploration-of-sudan-pyramids-is-like-something-out-of-tomb-raider-52994","target":"_blank"}},[_c('img',{staticStyle:{"height":"50px !important"},attrs:{"src":"/img/other/IflScience-Logo.png","alt":"IFL-Science"}})])]),_c('div',{staticClass:"column is-full-mobile is-3-desktop m-auto"},[_c('a',{attrs:{"href":"https://www.youtube.com/watch?v=egsEEjQbDrE","target":"_blank"}},[_c('img',{staticStyle:{"height":"50px !important"},attrs:{"src":"/img/other/YouTube-Logo.png","alt":"YouTube"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax columns image-4 p-6 mb-0 ml-0-mobile mr-0-mobile min-width-100-vw"},[_c('div',{staticClass:"column mt-auto mb-auto is-fullheight is-two-thirds is-full-mobile pt-6 pb-6 is-size-2 is-rounded glass-content masthead has-text-centered ml-auto"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full mt-auto"},[_c('h5',{staticClass:"has-text-weight-bold is-size-4-mobile"},[_vm._v("The Elite Depths")])]),_c('div',{staticClass:"column is-size-4 is-size-6-mobile is-full mt-auto has-text-left has-text-centered-mobile"},[_c('p',{staticClass:"px-6 pb-4"},[_vm._v("In times long past, the civilizations along the Nile crafted over 350 majestic pyramids, of which around 100 still grace the landscape today. A select few of these ancient marvels now guard their secrets beneath the waters, with only one pyramid and its submerged chambers having been explored by divers. This exclusive dive is a privilege reserved for a distinguished few within the Club.")]),_c('p',{staticClass:"px-6"},[_vm._v("In comparison, over 20,000 intrepid souls have reached the South Pole, more than 5,000 climbers have conquered Mount Everest, upwards of 500 individuals have ventured into space, and just twelve have left their footprints on the Moon.")])])])])])
}]

export { render, staticRenderFns }