<template>
  <div class="p-0 has-text-centered is-rounded is-grid profile-card" :style="`background-image: url('${background}');`">
    <div class="inner-content">
      <h3 class="has-text-weight-bold mt-auto">{{ name }}</h3>

      <div class="columns">
        <div v-if="pyramid" class="column is-one-half is-half-mobile m-auto">
          <b-image style="height: 100px; width: 100px; border-radius: 100px;" class="has-background-white is-fullheight m-auto" src="/img/pyramid/logo.png" alt="Pyramid Dive Club"/>
        </div>
        <div v-if="deadSeaDivingSociety" class="column is-half is-half-mobile m-auto">
          <b-image style="height: 100px; width: 100px; border-radius: 100px;" class="has-background-white is-fullheight m-auto" src="/img/pyramid/Dead-Sea-Diving-Society-Logo.png" alt="Pyramid Dive Club"/>
        </div>
      </div>

      <div class="full-with p-3 mt-auto mb-2 is-bottom">
        <b-button @click="showModal" class="full-width" style=""><a>About</a></b-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    name: String,
    description: String,
    background: String,
    pyramid: Boolean,
    deadSeaDivingSociety: Boolean
  },
  methods: {
    showModal (user, description) {
      this.$emit('doShowUser', this.name, this.description)
    }
  }
}
</script>

<style scoped>

.profile-card {
  height: 30em;
  background-position: center;
  background-size: cover
}

.profile-card .inner-content {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  color: white !important;
  border-radius: 2em;
  box-shadow: inset 0 0 50px 30px rgba(0,0,0,0.4);
}

</style>
